import React from "react";
import Box from "@mui/material/Box";

function Accommodation() {
    return (
        <Box sx={{ width: "100%" }} className="accommodation" id="accommodation">
            <h1 className="accommodation-title orbitron-font-set">Hotel Accommodation</h1>
            <div className="hotel-content">
                <p>
                    Enjoy a luxurious stay during your visit. We recommend staying at{" "}
                    <a
                        href="https://www.vivantahotels.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Hotel Taj Vivanta, Dwarka
                    </a>
                    . where elegance meets comfort.
                </p>
            </div>
        </Box>
    );
}

export default Accommodation;