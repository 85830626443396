import React, { useState, useRef } from "react";
import emailjs from 'emailjs-com';

function Registration() {
  // Form ref to get the form element
  const formRef = useRef();
  const [loading, setLoading] = useState(false);
  // const fileInputRef = useRef();

  const calculateMoneyTransferDetails = (registrationFor) => {
    const currentDate = new Date();
    const earlyBirdEndDate = new Date("2024-10-31");
    const regularEndDate = new Date("2024-12-31");

    if (registrationFor === "VAI Members") {
      if (currentDate <= earlyBirdEndDate) return "INR 12000";
      if (currentDate <= regularEndDate) return "INR 13000";
      return "INR 14000";
    }

    if (registrationFor === "Non VAI Members") {
      if (currentDate <= earlyBirdEndDate) return "INR 13200";
      if (currentDate <= regularEndDate) return "INR 14200";
      return "INR 15200";
    }

    if (registrationFor === "Accompanying Person") {
      return "INR 10000"; // Fixed price
    }

    if (registrationFor === "Students" || registrationFor === "Nurses") {
      if (currentDate <= earlyBirdEndDate) return "INR 3600";
      if (currentDate <= regularEndDate) return "INR 4600";
      return "INR 5600";
    }

    if (registrationFor === "International Deligates") {
      if (currentDate <= earlyBirdEndDate) return "USD 150";
      if (currentDate <= regularEndDate) return "USD 160";
      return "USD 170";
    }

    return ""; // Default empty price if something goes wrong
  };

  const [formData, setFormData] = useState({
    title: "",
    firstNameMiddleName: "",
    lastName: "",
    gender: "",
    designation: "",
    mobile: "",
    email: "",
    membershipNo: "",
    moneyTransferDetails: calculateMoneyTransferDetails("VAI Members"),
    registrationFor: "VAI Members",
    address: "",
    transactionId: "",
    bankName: "",
    transactionDate: ""
  });

  // const [file, setFile] = useState(null); // State to handle the uploaded file
  const [errors, setErrors] = useState({});

  const validate = () => {
    let tempErrors = {};
    let isValid = true;

    if (formData.gender === "") {
      tempErrors["gender"] = "Gender is required";
      isValid = false;
    }

    if (formData.title === "") {
      tempErrors["title"] = "Title is required";
      isValid = false;
    }

    if (formData.lastName === "") {
      tempErrors["lastName"] = "Last Name is required";
      isValid = false;
    }

    if (formData.firstNameMiddleName === "") {
      tempErrors["firstNameMiddleName"] = "First Name & Middle Name are required";
      isValid = false;
    }


    if (formData.address === "") {
      tempErrors["address"] = "Address is required";
      isValid = false;
    }

    if (formData.email === "") {
      tempErrors["email"] = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      tempErrors["email"] = "Email is invalid";
      isValid = false;
    }

    if (formData.mobile === "") {
      tempErrors["mobile"] = "Mobile number is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.mobile)) {
      tempErrors["mobile"] = "Mobile number must be 10 digits";
      isValid = false;
    }

    if (formData.transactionId === "") {
      tempErrors["transactionId"] = "Transaction ID is required";
      isValid = false;
    }

    if (formData.bankName === "") {
      tempErrors["bankName"] = "Bank Name is required";
      isValid = false;
    }

    if (formData.transactionDate === "") {
      tempErrors["transactionDate"] = "Transaction Date is required";
      isValid = false;
    }


    // if (!file) {
    //   tempErrors["file"] = "Screenshot of payment is required";
    //   isValid = false;
    // } else if (!/\.(jpe?g|png|pdf)$/i.test(file.name)) {
    //   tempErrors["file"] = "Only JPEG, PNG, and PDF files are allowed";
    //   isValid = false;
    // } else if (file.size > 10 * 1024 * 1024) {
    //   tempErrors["file"] = "File size must be less than 10 MB";
    //   isValid = false;
    // }

    setErrors(tempErrors);
    return isValid;
  };

  // const handleFileChange = (e) => {
  //   setFile(e.target.files[0]);
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      moneyTransferDetails: name === "registrationFor" ? calculateMoneyTransferDetails(value) : prevData.moneyTransferDetails
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      // Send the email using EmailJS with the form element reference
      emailjs
        .sendForm(
          "service_1umaejl", // EmailJS service ID
          "template_ecd3q65", // EmailJS template ID
          formRef.current, // Reference to the actual form element
          "ya9mpxXzuobO_azUm" // EmailJS user ID
        )
        .then((result) => {
          emailjs
            .sendForm(
              "service_1umaejl", // EmailJS service ID
              "template_n99ips6", // EmailJS template ID
              formRef.current, // Reference to the actual form element
              "ya9mpxXzuobO_azUm" // EmailJS user ID
            )
          console.log('Email successfully sent!', result.text);
          alert('Thank you for your request. Our team will review it and get back to you shortly.');

          // Clear form fields after submission
          setFormData({
            title: "",
            firstNameMiddleName: "",
            lastName: "",
            gender: "",
            designation: "",
            mobile: "",
            email: "",
            membershipNo: "",
            moneyTransferDetails: calculateMoneyTransferDetails("VAI Members"),
            registrationFor: "VAI Members",
            address: "",
            transactionId: "",
            bankName: "",
            transactionDate: ""
          });
          // setFile(null); // Clear the uploaded file

          // // Scroll to top of the page
          // if (fileInputRef.current) {
          //   fileInputRef.current.value = ""; // Clear the file input
          // }
          setLoading(false);
          window.scrollTo(0, 0);
        })
        .catch((error) => {
          setLoading(false);
          console.log('Email sending error:', error.text);
          alert('An error occurred while sending your registration.');
        });
    } else {
      alert('Please correct the highlighted errors.');
    }
  };

  return (
    <div className="registration" id="reg">
      <div className="reg-heading orbitron-font-set">
        Registration Fee Details
      </div>
      <div className="reg-fee-details">
        <table className="reg-table">
          <tbody>
            <tr>
              <th>
                <b>Attendee types</b>
              </th>
              <th>
                <b>Early Bird Registration</b>
                <br />
                UP TO 31 Oct 2024
              </th>
              <th>
                <b>Regular Registration</b> <br /> UP TO 31 DEC 2024
              </th>
              <th>
                <b>Spot Registration</b> <br /> UP TO 31 Jan 2025
              </th>
            </tr>
            <tr>
              <td>VAI members</td>
              <td>INR 12000</td>
              <td>INR 13000</td>
              <td>INR 14000</td>
            </tr>
            <tr>
              <td>Non VAI members</td>
              <td>INR 13200</td>
              <td>INR 14200</td>
              <td>INR 15200</td>
            </tr>
            <tr>
              <td>Accompanying Person</td>
              <td>INR 10000</td>
              <td>INR 10000</td>
              <td>INR 10000</td>
            </tr>
            <tr>
              <td>Subsidised Registration for Students</td>
              <td>INR 3600</td>
              <td>INR 4600</td>
              <td>INR 5600</td>
            </tr>
            <tr>
              <td>Subsidised Registration for Nurses / Paramedics</td>
              <td>INR 3600</td>
              <td>INR 4600</td>
              <td>INR 5600</td>
            </tr>
          </tbody>
        </table>
        <div className="gst">* GST Not Applicable </div>
        <div className="for-reg  orbitron-font-set">Foreign Registration</div>
        <table className="reg-table">
          <tbody>
            <tr>
              <th>
                <b>Attendee types</b>
              </th>
              <th>
                <b>Early Bird Registration</b>
                <br />
                UP TO 31 Oct 2024
              </th>
              <th>
                <b>Regular Registration</b> <br /> UP TO 31 DEC 2024
              </th>
              <th>
                <b>Spot Registration</b> <br /> UP TO 31 Jan 2025
              </th>
            </tr>
            <tr>
              <td>International Deligates</td>
              <td>USD 150</td>
              <td>USD 160</td>
              <td>USD 170</td>
            </tr>
          </tbody>
        </table>
        <div className="gst">* GST Not Applicable </div>
      </div>
      <div className="registration-form-section" id="reg-form">
        {/* Bank Details Table */}
        <div className="bank-details-container">
          <h3 className="bank-details-heading">Bank Details for Payment</h3>
          <div className="bank-details-content">
            <div className="bank-detail">
              <i className="fas fa-university"></i>
              <div>
                <strong>Account Name:</strong> Venous Association of India
              </div>
            </div>
            <div className="bank-detail">
              <i className="fas fa-id-card"></i>
              <div>
                <strong>Account Number:</strong> 918010019239711
              </div>
            </div>
            <div className="bank-detail">
              <i className="fas fa-code"></i>
              <div>
                <strong>IFS Code:</strong> UTIB0001518
              </div>
            </div>
            <div className="bank-detail">
              <i className="fas fa-building"></i>
              <div>
                <strong>Bank:</strong> Axis Bank Ltd.
              </div>
            </div>
            <div className="bank-detail">
              <i className="fas fa-map-marker-alt"></i>
              <div>
                <strong>Branch:</strong> Sector 16, Chandigarh - 160015
              </div>
            </div>
          </div>
        </div>
        <span className="error">
          Please make the payment to the above bank account and fill the below form for the acknowledgement. For other queries please call/whatsapp us at +91-82838-17442 (Ms. Shiwangi Thapa - Secretariat - VAI Office)
        </span><br />
        <br />
        <div className="reg-heading orbitron-font-set">Registration Form for VAICON 2025</div>
        <form ref={formRef} onSubmit={handleSubmit} className="registration-form">
          <div className="form-group">
            <div>
              <select
                name="title"
                value={formData.title}
                onChange={handleChange}
              >
                <option value="">Select Title*</option>
                <option value="Prof.">Prof.</option>
                <option value="Dr.">Dr.</option>
                <option value="Mr.">Mr.</option>
                <option value="Ms.">Ms.</option>
                <option value="Mrs.">Mrs.</option>
              </select>
              {errors.title && <p className="error">{errors.title}</p>}
            </div>
            <div>
              <input
                type="text"
                name="firstNameMiddleName"
                placeholder="First Name & Middle Name*"
                value={formData.firstNameMiddleName}
                onChange={handleChange}
              />
              {errors.firstNameMiddleName && <p className="error">{errors.firstNameMiddleName}</p>}
            </div>
            <div>
              <input
                type="text"
                name="lastName"
                placeholder="Last Name*"
                value={formData.lastName}
                onChange={handleChange}
              />
              {errors.lastName && <p className="error">{errors.lastName}</p>}
            </div>
          </div>

          <div className="form-group">
            <div>
              <select
                name="gender"
                value={formData.gender}
                onChange={handleChange}
                className="full-width"
              >
                <option value="">Select Gender*</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
              {errors.gender && <p className="error">{errors.gender}</p>}
            </div>
            <div>
              <input
                type="text"
                name="designation"
                placeholder="Designation"
                value={formData.designation}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="form-group">
            <div>
              <input
                type="number"
                name="mobile"
                placeholder="Mobile No*"
                value={formData.mobile}
                onChange={handleChange}
              />
              {errors.mobile && <p className="error">{errors.mobile}</p>}
            </div>
            <div>
              <input
                type="email"
                name="email"
                placeholder="E-mail*"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && <p className="error">{errors.email}</p>}
            </div>
          </div>

          <div className="form-group">
            <div>
              <input
                type="text"
                name="membershipNo"
                placeholder="Membership no. of VAI (if member)"
                value={formData.membershipNo}
                onChange={handleChange}
              />
            </div>
            <div>
              {/* Display the price dynamically based on the selection */}
              <input
                disabled
                type="text"
                placeholder="Details of Money Transfer"
                value={formData.moneyTransferDetails}
              />
              <input
                type="hidden"
                name="moneyTransferDetails"
                value={formData.moneyTransferDetails}
              />
            </div>
          </div>

          <div className="form-group">
            <select
              name="registrationFor" // Remove the asterisk here
              value={formData.registrationFor}
              onChange={handleChange}
              className="full-width"
            >
              <option value="VAI Members">VAI Members</option>
              <option value="Non VAI Members">Non VAI Members</option>
              <option value="Accompanying Person">Accompanying Person</option>
              <option value="Students">Students</option>
              <option value="International Deligates">International Deligates</option>
              <option value="Nurses">Nurses</option>
            </select>
          </div>

          <div className="form-group full-width">
            <textarea
              name="address"
              placeholder="Address*"
              value={formData.address}
              onChange={handleChange}
            />
          </div>
          {/* File Upload for Payment Screenshot */}
          {/* <div className="form-group">
            <label>Upload Screenshot of Payment (JPEG, PNG, PDF - Max 10MB)*</label>
            <input
              type="file"
              ref={fileInputRef}
              accept=".jpeg, .jpg, .png, .pdf"
              onChange={handleFileChange}
            />
            {errors.file && <p className="error">{errors.file}</p>}
          </div> */}
          {/* New Fields for Transaction Details */}
          <div className="reg-heading orbitron-font-set">Payment Details</div>
          <div className="form-group">
            <div>
              <input
                type="text"
                name="transactionId"
                placeholder="Transaction ID*"
                value={formData.transactionId}
                onChange={handleChange}
              />
              {errors.transactionId && <p className="error">{errors.transactionId}</p>}
            </div>
            <div>
              <input
                type="text"
                name="bankName"
                placeholder="Bank Name*"
                value={formData.bankName}
                onChange={handleChange}
              />
              {errors.bankName && <p className="error">{errors.bankName}</p>}
            </div>
          </div>

          <div className="form-group full-width">
            <span>Transaction Date*</span>
            <input
              type="date"
              name="transactionDate"
              placeholder="Transaction Date*"
              value={formData.transactionDate}
              onChange={handleChange}
              max={new Date().toISOString().split("T")[0]}  // Prevent future dates
            />
            {errors.transactionDate && <p className="error">{errors.transactionDate}</p>}
          </div>

          <div className="form-group full-width">
            <button type="submit" className="submit-btn" disabled={loading}>
              {loading ? 'Submitting...' : 'Submit'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Registration;
