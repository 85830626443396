import React, { useState } from "react";

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="navbar">
      <div className="navbar-header">
        <button className="hamburger-menu" onClick={toggleMenu}>
          ☰
        </button>
      </div>
      <ul className={`navbar-content ${isMenuOpen ? "open" : ""}`}>
        <li>
          <a href="#about" onClick={toggleMenu}>About</a>
        </li>
        <li>
          <a href="#reg" onClick={toggleMenu}>Registration</a>
        </li>
        <li>
          <a href="#accommodation" onClick={toggleMenu}>
            Accommodation
          </a>
        </li>
        <li>
          <a href="#schedule" onClick={toggleMenu}>Schedule</a>
        </li>
        <li>
          <a href="#organizers" onClick={toggleMenu}>Organizers</a>
        </li>
        <li>
          <a href="#venue" onClick={toggleMenu}>Venue</a>
        </li>
        <li>
          <a href="#contact" onClick={toggleMenu}>Contact Us</a>
        </li>
      </ul>
    </div>
  );
}

export default Navbar;
